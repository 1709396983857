import React, { Component } from "react"
import { Language } from "../../store/reducers/utilsReducer"
import { utilCurrency } from "../../utils/region"

export interface Rate {
  price: number
  weight: number
  type: string
}

export interface Title {
  text: string
  style: {
    fontSize: string
    fontFamily: string | undefined
  }
}
interface Props {
  content: Rate[]
  title?: Title
  fontSize: string
  height: number
  language?: Language
}

interface State {
  apexModule: any
}

export default class Rates extends Component<Props> {
  state: State = {
    apexModule: null,
  }

  componentDidMount = async () => {
    const reactApexChart = await import("react-apexcharts")
    this.setState({
      apexModule: reactApexChart.default,
    })
  }

  renderDataGenerator = () => {
    const category: string[] = []
    const pricing: number[] = []

    this.props.content.map(eachContent => {
      let label = ""
      if (this.props.language === Language.EN) {
        if (eachContent.type === "lesser") {
          label = "Less than " + eachContent.weight + "kg"
        } else if (eachContent.type === "equal-more") {
          label = eachContent.weight + "kg and above"
        }
      } else if (this.props.language === Language.BM) {
        if (eachContent.type === "lesser") {
          label = "Kurang daripada " + eachContent.weight + "kg"
        } else if (eachContent.type === "equal-more") {
          label = eachContent.weight + "kg dan ke atas"
        }
      } else {
        if (eachContent.type === "lesser") {
          label = "少于 " + eachContent.weight + "kg"
        } else if (eachContent.type === "equal-more") {
          label = eachContent.weight + "kg 及以上"
        }
      }

      category.push(label)
      pricing.push(eachContent.price)
      return true
    })

    return { category, pricing }
  }

  renderOptionsGenerator = () => {
    const data = this.renderDataGenerator()
    return {
      series: [
        {
          name: "Rating",
          data: data.pricing,
        },
      ],
      option: {
        fill: { colors: ["#6dba62"] },
        chart: {
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "top",
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val: number) {
            return utilCurrency() + " " + val.toFixed(2) + "/kg"
          },
          offsetY: -30,
          style: {
            fontSize: this.props.fontSize,
            colors: ["#6dba62"],
          },
        },
        xaxis: {
          categories: data.category,
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            style: {
              colors: [],
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: "bold",
              cssClass: "apexcharts-xaxis-label",
            },
          },
        },
        tooltips: { enabled: false },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val: number) {
              return utilCurrency() + " " + val.toFixed(2) + "/kg"
            },
          },
        },
        title: {
          ...this.props.title,
        },
      },
    }
  }

  render() {
    const data = this.renderOptionsGenerator()
    const APEXModule = this.state.apexModule
    return (
      <div id="chart">
        {APEXModule && (
          <APEXModule
            options={data.option}
            series={data.series}
            type="bar"
            height={this.props.height}
          />
        )}
      </div>
    )
  }
}
