import { ChartPieIcon, SpeakerphoneIcon } from "@heroicons/react/outline"
import { Link } from "gatsby"
import React, { Component } from "react"
import { Language } from "../../store/reducers/utilsReducer"
import { localeContent } from "../../utils/locale"
import {
  firstTier,
  fourthTier,
  secondTier,
  thirdTier,
} from "../../utils/region"
import Button from "../base/Button"
import Rates, { Rate, Title } from "../base/Rates"

interface Props {
  language: Language
}

const rates: Rate[] = [
  {
    price: firstTier,
    weight: 10,
    type: "lesser",
  },
  {
    price: secondTier,
    weight: 50,
    type: "lesser",
  },
  {
    price: thirdTier,
    weight: 100,
    type: "lesser",
  },
  {
    price: fourthTier,
    weight: 100,
    type: "equal-more",
  },
]

export default class Pricing extends Component<Props> {
  renderPricingTitle = () => {
    if (this.props.language === Language.EN) {
      return "Selling Rates of Your UCO"
    } else if (this.props.language === Language.BM) {
      return "Harga Jualan Minyak Masak Terpakai Anda"
    } else {
      return "您的 UCO 的销售率"
    }
  }

  renderFAQLink = () => {
    if (this.props.language === Language.CH) {
      return "/ch/faqs"
    } else if (this.props.language === Language.BM) {
      return "/bm/faqs"
    } else {
      return "/faqs"
    }
  }

  render() {
    const language = this.props.language
    const title: Title = {
      text: this.renderPricingTitle(),
      style: {
        fontSize: "18px",
        fontFamily: undefined,
      },
    }

    return (
      <div className="py-16 mt-16 bg-gray-50 overflow-hidden lg:py-24">
        <div className="relative max-w-md mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl sm:max-w-3xl">
          <div className="relative mt-12 sm:mt-16 lg:mt-24">
            <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-24 lg:items-center">
              <div className="lg:col-start-2">
                <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
                  {localeContent(language).pricing.paragraph1}
                </h3>
                <p className="mt-3 text-lg text-gray-500">
                  {localeContent(language).pricing.paragraph2}
                </p>

                <dl className="mt-10 space-y-10">
                  <div className="relative">
                    <dt>
                      <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-arusgreen-500 text-white">
                        <ChartPieIcon className="h-6 w-6" aria-hidden="true" />
                      </div>
                      <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                        {localeContent(language).pricing.subtitle1}
                      </p>
                    </dt>
                    <dd className="mt-2 ml-16 text-base text-gray-500">
                      {localeContent(language).pricing.subtitle2}
                    </dd>
                  </div>

                  <div className="relative">
                    <dt>
                      <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-arusgreen-500 text-white">
                        <SpeakerphoneIcon
                          className="h-6 w-6"
                          aria-hidden="true"
                        />
                      </div>
                      <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                        {localeContent(language).pricing.subtitle3}
                      </p>
                    </dt>
                    <dd className="mt-2 ml-16 text-base text-gray-500">
                      {localeContent(language).pricing.content}
                    </dd>
                  </div>
                </dl>

                <Link to={this.renderFAQLink()}>
                  <Button
                    className="mt-10 mb-4 w-full sm:max-w-xs"
                    type="normal"
                    text={localeContent(language).pricing.button}
                  />
                </Link>
              </div>

              <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
                <Rates
                  content={rates}
                  title={title}
                  fontSize="16px"
                  height={350}
                  language={this.props.language}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
