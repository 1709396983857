import React, { Component } from "react"
import LogoAmanz from "../../images/logo_amanz.png"
import LogoDNA from "../../images/logo_dna.png"
import LogoEpic from "../../images/logo_epic.png"
import LogoMDEC from "../../images/logo_mdec.png"
import LogoSays from "../../images/logo_says.png"
import { Language } from "../../store/reducers/utilsReducer"
import { localeContent } from "../../utils/locale"

interface Props {
  language: Language
}

const featuredLocations = [
  {
    name: "Epic",
    link:
      "https://journal.epic.my/responsible-used-cooking-oil-disposal-neighbourhood/",
    image: LogoEpic,
  },
  {
    name: "Says",
    link:
      "https://says.com/my/lifestyle/places-you-can-recycle-your-used-cooking-oil",
    image: LogoSays,
  },
  {
    name: "Amanz Media",
    link: "https://amanz.my/2021290946/",
    image: LogoAmanz,
  },
  {
    name: "MDEC",
    link: "https://blog.mdec.my/?p=1196",
    image: LogoMDEC,
  },
  {
    name: "Digital News Asia",
    link:
      "https://www.digitalnewsasia.com/startups/arus-oil-cult-creative-winners-alpha-startup-digital-accelerator-2021",
    image: LogoDNA,
  },
]

export default class Featured extends Component<Props> {
  render() {
    const language = this.props.language
    return (
      <div className="bg-white flex flex-col items-center">
        <h2 className="text-3xl font-extrabold text-gray-900 mt-24 tracking-tight sm:text-4xl">
          {localeContent(language).featured.title}
        </h2>
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-2 gap-8 md:grid-cols-4">
            {featuredLocations.map((eachFeatured, index: number) => (
              <div
                key={index}
                className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1"
              >
                <a target="_blank" rel="noreferrer" href={eachFeatured.link}>
                  <img
                    className="h-12"
                    alt={eachFeatured.name}
                    src={eachFeatured.image}
                  />
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
}
