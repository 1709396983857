import React, { Component } from "react"

interface Props {
  text: string
}

export default class Banner extends Component<Props> {
  render() {
    return (
      <div className="relative bg-arusgreen-600 w-full z-50">
        <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
          <div className="pr-16 sm:text-center sm:px-16">
            <p className="text-xs sm:text-sm text-white">
              {this.props.text}{" "}
              <a
                className="underline"
                target="_blank"
                rel="noreferrer"
                href="https://leet.capital/company/arus-oil"
              >
                https://leet.capital/company/arus-oil
              </a>
            </p>
          </div>
        </div>
      </div>
    )
  }
}
