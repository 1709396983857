import React, { Component } from "react"
import OilBackground from "../../images/home_oil.jpg"
import { Language } from "../../store/reducers/utilsReducer"
import { localeContent } from "../../utils/locale"

interface Props {
  language: Language
}

export default class Mission extends Component<Props> {
  renderMissionDivider = () => {
    const missionContent = [
      {
        title: "Founded",
        content: "2018",
      },
      {
        title: "Presence",
        content: "Selangor",
      },
      {
        title: "Used Cooking Oil Recycled",
        content: "250 Tonnes",
      },
      {
        title: "MPOB License No.",
        content: "619709017000",
      },
    ]

    const missionContentBM = [
      {
        title: "Diasaskan",
        content: "2018",
      },
      {
        title: "Lokasi",
        content: "Selangor",
      },
      {
        title: "Minyak masak terpakai dikitar semula",
        content: "250 Tan",
      },
      {
        title: "Nombor Lesen MPOB",
        content: "619709017000",
      },
    ]

    const missionContentCH = [
      {
        title: "成立于",
        content: "2018",
      },
      {
        title: "地点",
        content: "Selangor",
      },
      {
        title: "再循环的食用油",
        content: "250 Tan",
      },
      {
        title: "MPOB 证件号",
        content: "619709017000",
      },
    ]
    const missionDividerView: any = []

    if (this.props.language === Language.EN) {
      missionContent.map((eachContent, index: number) => {
        missionDividerView.push(
          <div className="border-t-2 border-gray-100 pt-6" key={index}>
            <dt className="text-base font-medium text-gray-500">
              {eachContent.title}
            </dt>
            <dd className="text-xl font-extrabold tracking-tight text-gray-900 whitespace-nowrap overflow-ellipsis overflow-hidden">
              {eachContent.content}
            </dd>
          </div>
        )
        return null
      })
    } else if (this.props.language === Language.BM) {
      missionContentBM.map((eachContent, index: number) => {
        missionDividerView.push(
          <div className="border-t-2 border-gray-100 pt-6" key={index}>
            <dt className="text-base font-medium text-gray-500">
              {eachContent.title}
            </dt>
            <dd className="text-xl font-extrabold tracking-tight text-gray-900 whitespace-nowrap overflow-ellipsis overflow-hidden">
              {eachContent.content}
            </dd>
          </div>
        )
        return null
      })
    } else {
      missionContentCH.map((eachContent, index: number) => {
        missionDividerView.push(
          <div className="border-t-2 border-gray-100 pt-6" key={index}>
            <dt className="text-base font-medium text-gray-500">
              {eachContent.title}
            </dt>
            <dd className="text-xl font-extrabold tracking-tight text-gray-900 whitespace-nowrap overflow-ellipsis overflow-hidden">
              {eachContent.content}
            </dd>
          </div>
        )
        return null
      })
    }

    return missionDividerView
  }

  render() {
    const language = this.props.language
    return (
      <div className="relative my-20">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
          <div className="relative sm:py-16 lg:py-0">
            <div
              aria-hidden="true"
              className="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen"
            >
              <div className="absolute inset-y-0 right-1/2 w-full bg-gray-50 rounded-r-3xl lg:right-72"></div>
              <svg
                className="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12"
                width="404"
                height="392"
                fill="none"
                viewBox="0 0 404 392"
              >
                <defs>
                  <pattern
                    id="02f20b47-fd69-4224-a62a-4c9de5c763f7"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x="0"
                      y="0"
                      width="4"
                      height="4"
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width="404"
                  height="392"
                  fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)"
                />
              </svg>
            </div>
            <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
              <div className="relative pt-64 pb-10 rounded-2xl shadow-xl overflow-hidden">
                <img
                  className="absolute inset-0 h-full w-full object-cover"
                  src={OilBackground}
                  alt=""
                />
                <div className="absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent opacity-80"></div>
                <div className="relative px-8">
                  <blockquote className="mt-8">
                    <div className="relative text-lg font-medium text-white md:flex-grow">
                      <p className="relative">
                        {localeContent(language).mission.paragraph1}
                      </p>
                    </div>
                    <footer className="mt-4">
                      <p className="text-base font-semibold text-arusgreen-100">
                        {localeContent(language).mission.paragraph2}
                      </p>
                    </footer>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>

          <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
            <div className="pt-12 sm:pt-16 lg:pt-20">
              <h2 className="text-3xl text-gray-900 font-extrabold tracking-tight sm:text-4xl">
                {localeContent(language).mission.paragraph3}
              </h2>
              <div className="mt-6 text-gray-500 space-y-6">
                <p className="text-base leading-7 text-justify">
                  {localeContent(language).mission.paragraph4}
                </p>
                <p className="text-base leading-7 text-justify">
                  {localeContent(language).mission.paragraph5}
                </p>
                <p className="text-base leading-7 text-justify">
                  {localeContent(language).mission.paragraph6}
                </p>
              </div>
            </div>

            <div className="mt-10">
              <dl className="grid grid-cols-2 gap-x-4 gap-y-8">
                {this.renderMissionDivider()}
              </dl>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
